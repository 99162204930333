img:not(.favicon) {
  width: 70vw;
  max-width: 1000px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 12px;
}

@keyframes smoothTransition {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Core {
  animation: smoothTransition 2s normal;
}